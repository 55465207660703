
import ApiServices from "../apiServices";
import axios from '@axios'

export default class NestleApiServices extends ApiServices {
  constructor() {
    super("/agrosuper");

    this.historicoData = {  

      search: this.baseUrl + "/historicoData/search",
  
      presiones: {
        historicoConfig: this.baseUrl + "/historicoData/presiones/historico/config",
        historicoData: this.baseUrl + "/historicoData/presiones/historico/data",
        chart00: this.baseUrl + "/historicoData/presiones/chart00",
        stats00: this.baseUrl + "/historicoData/presiones/stats00",
      },

      temperatura: {
        historicoConfig: this.baseUrl + "/historicoData/temperatura/historico/config",
        historicoData: this.baseUrl + "/historicoData/temperatura/historico/data",
        chart00: this.baseUrl + "/historicoData/temperatura/chart00",
        stats00: this.baseUrl + "/historicoData/temperatura/stats00",
      },
 
      electrico: {
        historicoConfig: this.baseUrl + "/historicoData/electrico/historico/config",
        historicoData: this.baseUrl + "/historicoData/electrico/historico/data",
        chart00: this.baseUrl + "/historicoData/electrico/chart00",
        stats00: this.baseUrl + "/historicoData/electrico/stats00",
      },

      clima: {
        historicoConfig: this.baseUrl + "/historicoData/clima/historico/config",
        historicoData: this.baseUrl + "/historicoData/clima/historico/data",
        chart00: this.baseUrl + "/historicoData/clima/chart00",
        stats00: this.baseUrl + "/historicoData/clima/stats00",
      },


      varafria: {
        historicoConfig: this.baseUrl + "/historicoData/varafria/historico/config",
        historicoData: this.baseUrl + "/historicoData/varafria/historico/data",
        chart00: this.baseUrl + "/historicoData/varafria/chart00",
        stats00: this.baseUrl + "/historicoData/varafria/stats00",

        chart01: this.baseUrl + "/historicoData/varafria/chart01",

        chart02: this.baseUrl + "/historicoData/varafria/chart02",
        chart03: this.baseUrl + "/historicoData/varafria/chart03",

      },      



      varacaliente: {
        historicoConfig: this.baseUrl + "/historicoData/varacaliente/historico/config",
        historicoData: this.baseUrl + "/historicoData/varacaliente/historico/data",
        chart00: this.baseUrl + "/historicoData/varacaliente/chart00",
        stats00: this.baseUrl + "/historicoData/varacaliente/stats00",

        chart01: this.baseUrl + "/historicoData/varacaliente/chart01",

        chart02: this.baseUrl + "/historicoData/varacaliente/chart02",

      },         


      recepcion: {
        historicoConfig: this.baseUrl + "/historicoData/recepcion/historico/config",
        historicoData: this.baseUrl + "/historicoData/recepcion/historico/data",
        chart00: this.baseUrl + "/historicoData/recepcion/chart00",
        stats00: this.baseUrl + "/historicoData/recepcion/stats00",

        chart01: this.baseUrl + "/historicoData/recepcion/chart01",

        chart02: this.baseUrl + "/historicoData/recepcion/chart02",

      },      

      correlacion: {
        historicoConfig: this.baseUrl + "/historicoData/correlacion/historico/config",
        historicoData: this.baseUrl + "/historicoData/correlacion/historico/data",
        chart00: this.baseUrl + "/historicoData/correlacion/chart00",
        stats00: this.baseUrl + "/historicoData/correlacion/stats00",

        chart01: this.baseUrl + "/historicoData/correlacion/chart01",

        chart02: this.baseUrl + "/historicoData/correlacion/chart02",

      }, 


    };

    this.historicoPredicciones = {




      stats00: this.baseUrl + "/historicoPredicciones/stats00",
      stats01: this.baseUrl + "/historicoPredicciones/stats01",
      table00Config: this.baseUrl + "/historicoPredicciones/table00/config",
      table00Data: this.baseUrl + "/historicoPredicciones/table00/data", 

      table01Config: this.baseUrl + "/historicoPredicciones/table01/config",
      table01Data: this.baseUrl + "/historicoPredicciones/table01/data", 

      prediccionAceiteChart00: this.baseUrl + "/historicoPredicciones/chartPrediccionAceite",
      prediccionConsumoChart00: this.baseUrl + "/historicoPredicciones/chartPrediccionConsumo",      

      /* matrizRendimientoPorFundoChart00: this.baseUrl + "/historicoPredicciones/matrizRendimientoPorFundoChart00",
      rendimientoPromedioPorFundoChart00: this.baseUrl + "/historicoPredicciones/rendimientoPromedioPorFundoChart00",

      rendimientoPorEquipoChart00: this.baseUrl + "/historicoPredicciones/rendimientoPorEquipoChart00",


      */
  }

    this.historicoAutomationResultsConfig = this.baseUrl + "/historicoAutomationResults/config"
    this.historicoAutomationResultsData = this.baseUrl + "/historicoAutomationResults/data"
    this.historicoPrediccionesMezclas = {
      historicoPrediccionesMezclasStats: this.baseUrl + "/historicoPrediccionesMezclas/historicoPrediccionesMezclasStats",
  };


  this.modeloPrediccion = {

    prediccionPesoPromedioPesoVivoChart: this.baseUrl + "/modeloPrediccion/prediccionPesoPromedioPesoVivoChart",


    prediccionPesoPromedioVaraFriaChart: this.baseUrl + "/modeloPrediccion/prediccionPesoPromedioVaraFriaChart",
    prediccionDistribucionLomosVaraFriaChart: this.baseUrl + "/modeloPrediccion/prediccionDistribucionLomosVaraFriaChart",
    prediccionesVaraFriaTabla: this.baseUrl + "/modeloPrediccion/prediccionesVaraFriaTabla",

    stats01: this.baseUrl + "/modeloPrediccion/stats01",
    

    historicoPrediccionesConfig: this.baseUrl + "/modeloPrediccion/historicoPredicciones/config",
    historicoPrediccionesData: this.baseUrl + "/modeloPrediccion/historicoPredicciones/data",
    
    pesovivo: {

      historicoPrediccionesConfig: this.baseUrl + "/modeloPrediccion/historicoPredicciones/pesovivo/config",
      historicoPrediccionesData: this.baseUrl + "/modeloPrediccion/historicoPredicciones/pesovivo/data",

      stats01: this.baseUrl + "/modeloPrediccion/pesovivo/stats01",


      prediccionDistribucionPesoVivoChart: this.baseUrl + "/modeloPrediccion/pesovivo/prediccionDistribucionPesoVivoChart",

      prediccionesPesoVivoTabla:  this.baseUrl + "/modeloPrediccion/pesovivo/prediccionesPesoVivoTabla",


      prediccionesPesoPromedioDiarioPesoVivoTabla: this.baseUrl + "/modeloPrediccion/pesovivo/prediccionesPesoPromedioDiarioPesoVivoTabla",


      distribucionDensidadPesoVivoTabla: this.baseUrl + "/modeloPrediccion/pesovivo/distribucionDensidadPesoVivoTabla",


      statsDistribucionPesoPesoVivo: this.baseUrl + "/modeloPrediccion/pesovivo/statsDistribucionPesoPesoVivo",

    },

    varafria: {

      prediccionesPesoPromedioDiarioVaraFriaTabla: this.baseUrl + "/modeloPrediccion/varafria/prediccionesPesoPromedioDiarioVaraFriaTabla",
      distribucionDensidadVaraFriaTabla: this.baseUrl + "/modeloPrediccion/varafria/distribucionDensidadVaraFriaTabla",
      statsDistribucionPesoVaraFria: this.baseUrl + "/modeloPrediccion/varafria/statsDistribucionPesoVaraFria",




    },
      

    stats00: this.baseUrl + "/historicoPredicciones/stats00",
    table00Config: this.baseUrl + "/historicoPredicciones/table00/config",
    table00Data: this.baseUrl + "/historicoPredicciones/table00/data", 

    table01Config: this.baseUrl + "/historicoPredicciones/table01/config",
    table01Data: this.baseUrl + "/historicoPredicciones/table01/data", 

    prediccionAceiteChart00: this.baseUrl + "/historicoPredicciones/chartPrediccionAceite",
    prediccionConsumoChart00: this.baseUrl + "/historicoPredicciones/chartPrediccionConsumo",      

    /* matrizRendimientoPorFundoChart00: this.baseUrl + "/historicoPredicciones/matrizRendimientoPorFundoChart00",
    rendimientoPromedioPorFundoChart00: this.baseUrl + "/historicoPredicciones/rendimientoPromedioPorFundoChart00",

    rendimientoPorEquipoChart00: this.baseUrl + "/historicoPredicciones/rendimientoPorEquipoChart00",


    */
}

  this.historicoAutomationResultsConfig = this.baseUrl + "/historicoAutomationResults/config"
  this.historicoAutomationResultsData = this.baseUrl + "/historicoAutomationResults/data"
  this.historicoPrediccionesMezclas = {
    historicoPrediccionesMezclasStats: this.baseUrl + "/historicoPrediccionesMezclas/historicoPrediccionesMezclasStats",
};




  this.historicoOptimizationsConfig = this.baseUrl + "/historicoOptimizations/config"
  this.historicoOptimizationsData = this.baseUrl + "/historicoOptimizations/data"


  this.detalleOptimizacion = {

    detalleOptimizacionTable: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable",
    detalleOptimizacionTable2: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable2",
    detalleOptimizacionParallelChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionParallelChart",
    detalleOptimizacionStats: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats",
    detalleOptimizacionGensChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionGensChart",

    detalleOptimizacionStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats2",

    
    

};



  }
}


