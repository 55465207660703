

export default [
  {
    header: "Modules"
  },
  {
    icon: "HomeIcon",
    title: "Home",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Inicio",
          route:
          {
            name: "agrosuper-home",
          }
        },

      ]
  },
  {
    icon: "DatabaseIcon",
    title: "Data",
    children:
      [

        {
          icon: "CircleIcon",
          title: "Histórico de data",
          route:
          {
            name: "agrosuper-historico-data",
          }
        }
      ]   
  },
  {
    icon: "TrendingUpIcon",
    title: "Predicciones",
    children:
      [
        /*
        {
          icon: "CircleIcon",
          title: "Simulación de Escenarios",
          route:
          {
            name: "not-active",
          }
        },
*/


        {
          icon: "CircleIcon",
          title: "Vara Fria",
          route:
          {
            name: "agrosuper-historico-predicciones",
          }
        },

        {
          icon: "CircleIcon",
          title: "Peso Vivo",
          route:
          {
            name: "agrosuper-historico-predicciones-peso-vivo",
          }
        }

      ]
  },

  {
    icon: "ListIcon",
    title: "Historico Optimizacion",
    route:
    {
        name: "historico-optimizations",
    }
},




]
