export default [
    {
      path: "/",
      name: "agrosuper-home",
      component: () => import("@/views/clients/agrosuper/home/Home.vue"),
      meta: {
        pageTitle: "Inicio",
        breadcrumb: [
          {
            text: "Panel de inicio agrosuper",
            active: true
          }
        ]
      }
    },
    {
      path: "/historico-data",
      name: "agrosuper-historico-data",
      component: () =>
        import(
          "@/views/clients/agrosuper/data/HistoricoAutomationResults.vue"
        ),
      meta: {
        pageTitle: "Histórico de Data",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/agrosuper-simulacion-escenarios",
      name: "agrosuper-simulacion-escenarios",
      component: () =>
        import(
          "@/views/clients/agrosuper/optimizaciones/HistoricoPlanificaciones.vue"
        ),
      meta: {
        pageTitle: "Simulación de Escenarios",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/historico-predicciones-vara-fria",
      name: "agrosuper-historico-predicciones",
      component: () =>
        import(
          "@/views/clients/agrosuper/historico-predicciones/HistoricoPredicciones2.vue"
        ),
      meta: {
        pageTitle: "Histórico de Predicciones",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/historico-predicciones-peso-vivo",
      name: "agrosuper-historico-predicciones-peso-vivo",
      component: () =>
        import(
          "@/views/clients/agrosuper/historico-predicciones-peso-vivo/HistoricoPredicciones2.vue"
        ),
      meta: {
        pageTitle: "Histórico de Predicciones",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/historico-optimizacion",
      name: "historico-optimizations",
      component: () => import("@/views/clients/agrosuper/historico-optimizations/HistoricoOptimizations.vue"),
      meta: {
        pageTitle: "Histórico de Optimizaciones",
        breadcrumb: [
          { 
            text: "Histórico de Optimizaciones",
            active: true,
          },
        ],
      },
    },    
    
  
    {
      path: "/optimizations/details/",
      name: "optimization-details",
      component: () =>
        import(
          "@/views/clients/agrosuper/historico-optimizations/DetailOptimization.vue"
        ),
      meta: {
        pageTitle: "Detalle de Optimización",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
  
  
    {
      path: "/not-active",
      name: "not-active",
      component: () =>
        import(
          "@/views/clients/agrosuper/error/NotActive.vue"
        ),
      meta: {
        pageTitle: "No activo",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  ];
  